<template>
    <div>
        <Checkbox v-for="(item, i) in options"
            :key="i"
            multiple
            v-model="questionValue"
            :disabled="readonly"
            slot-name="label"
            :error="incorrect"
            :input-value="item"
            :isActive=false
            :name="id"
        >
            <template>
                <span class="text--xs"
                      :class="colorClass">{{ item }}</span>
            </template>
        </Checkbox>
    </div>
</template>

<script>
import Checkbox from '@/components/widgets/forms/Checkbox';

export default {
    name: 'MultipleChoice',
    components: { Checkbox },
    data: () => ({
        questionValue: null
    }),
    props: {
        id: {
            type: String
        },
        options: {
            type: Array,
            required: true
        },
        values: {
            type: Array,
            'default': () => ([])
        },
        incorrect: {
            type: Boolean,
            'default': false
        },
        readonly: {
            type: Boolean,
            'default': true
        },
        required: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        colorClass () {
            return this.incorrect ? 'red--text font-weight-medium' : 'primary--text';
        }
    },
    created () {
        this.questionValue = this.values;
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";
    ::v-deep {
        & .v-input--selection-controls {
            margin-top: 0;
        }
        & .v-messages {
            display: none;
        }
        & .v-icon {
            font-size: 18px;
        }
        & .v-input__slot {
            margin-bottom: 0;
        }
        & .v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate) .v-icon {
            color: $primary !important;
        }
        & span[error="true"] .v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate) .v-icon {
                color: $red !important;
        }
    }
</style>

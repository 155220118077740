<template>
    <div class="question pb-2">
        <div v-if="showHeader" class="d-flex align-center justify-space-between mb-4">
            <p class="mb-0">{{question}}<strong v-if="required"> *</strong></p>
            <v-checkbox
                ref="notApplicable"
                v-if="not_applicable"
                v-model="notApplicable"
                class="test d-flex mt-0"
                :label="$t(`question.notApplicable`)"
            />
        </div>
        <div v-for="n in repeatableCount" :key="n" class="d-flex">
            <div class="full-width">
                <Component
                    :is="configureComponent(item).name"
                    v-bind="configureComponent(item).attributes"
                    :id="`${id}${n > 1 ? `-${n}` : ''}`"
                    :readonly="false"
                    :required="!notApplicable && required"
                    :label="question"
                />
            </div>
            <v-btn v-if="isRepeatable" icon color="primary" class="ml-4" @click="increment">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn v-if="isRepeatable" icon color="primary" @click="decrement" :disabled="(n === 1 && n === repeatableCount)">
                <v-icon>mdi-minus</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import configQuestionComponent from '@/mixins/configQuestionComponent';
import Rating from '@/components/questions/Rating';
import MultipleChoice from '@/components/questions/MultipleChoice';
import Percent from '@/components/questions/Percent';
import SingleChoice from '@/components/questions/SingleChoice';
import YesNo from '@/components/questions/YesNo';
import PicturesAnswers from '@/components/questions/PicturesAnswers';
import InfiniteScroll from '@/components/widgets/InfiniteScroll';
import QuestionDate from '@/components/questions/QuestionDate';
import QuestionText from '@/components/questions/QuestionText';
import QuestionTime from '@/components/questions/QuestionTime';
import QuestionValue from '@/components/questions/QuestionValue';
import QuestionInformation from '@/components/questions/QuestionInformation';
import QuestionHeader from '@/components/questions/QuestionHeader';
import { HEADER_ONLY_TYPES } from '@/constants/questionTypes';

export default {
    name: 'IncidentQuestion',
    mixins: [configQuestionComponent],
    components: { Rating, MultipleChoice, Percent, SingleChoice, YesNo, PicturesAnswers, InfiniteScroll, QuestionText, QuestionDate, QuestionTime, QuestionValue, QuestionInformation, QuestionHeader },
    data: () => ({
        repeatableCount: 1,
        notApplicable: false
    }),
    props: {
        answers: {
            type: Array
        },
        item: {
            type: Object
        },
        question: {
            type: String,
            'default': ''
        },
        question_type: {
            type: Number,
            'default': 0
        },
        required: {
            type: Boolean,
            'default': false
        },
        question_parameters: {
            type: String,
            'default': '[]'
        },
        id: {
            type: Number
        },
        config: {
            type: String
        },
        not_applicable: {
            type: Boolean
        }
    },
    computed: {
        isRepeatable () {
            try {
                const parsedConfig = JSON.parse(this.config);
                return parsedConfig.repeatable ?? false;
            } catch (e) {
                return false;
            }
        },
        showHeader () {
            return !HEADER_ONLY_TYPES.includes(this.question_type);
        }
    },
    methods: {
        increment () {
            this.repeatableCount++;
        },
        decrement () {
            this.repeatableCount--;
        }
    },
    watch: {
        notApplicable (newVal) {
            const answerIndex = this.answers.findIndex(ans => ans.id === this.id.toString());

            if (this.answers[answerIndex]) {
                this.answers[answerIndex] = {
                    ...this.answers[answerIndex],
                    not_applicable: newVal
                };
            }
        }
    },
    created () {
        this.answers.push({
            ...this.item,
            id: this.id.toString(),
            not_applicable: false,
            answer: null
        });
    }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/_variables.scss";

.question {
    width: 100%;
}
.test::v-deep {
    .v-messages {
        display: none;
    }
}
</style>

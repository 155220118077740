<template>
    <span v-if="readonly" :class="colorClass">{{ questionValue === '1' ? $t('answers.yes') : $t('answers.no') }}</span>
    <ValidationProvider v-else
                        :name="label"
                        :vid="id"
                        :rules="required ? {required: true} : {}"
                        v-slot="{ errors }">
        <v-switch
            class="mt-0"
            :readonly="readonly"
            :label="label"
            v-model="questionValue"
            :name="id"
            true-value="1"
            false-value="0"
            :error-messages="errors"
        />
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'YesNo',
    components: { ValidationProvider },
    data: () => ({
        questionValue: '0'
    }),
    props: {
        id: {
            type: String
        },
        value: {
            type: String,
            'default': '0'
        },
        label: {
            type: String
        },
        incorrect: {
            type: Boolean,
            'default': false
        },
        readonly: {
            type: Boolean,
            'default': true
        },
        required: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        colorClass () {
            return this.incorrect ? 'red--text font-weight-medium' : 'primary--text';
        }
    },
    created () {
        this.questionValue = this.value;
    }
};
</script>

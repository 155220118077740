<template>
    <span v-if="readonly" :class="colorClass">{{ questionValue }}</span>
    <TextField
        v-else
        :label="label"
        :disabled="readonly"
        :name="id"
        v-model="questionValue"
        type="number"
        :rules="required ? {required: true} : {}"
    />
</template>

<script>
import TextField from '@/components/widgets/forms/TextField';
export default {
    name: 'QuestionValue',
    components: { TextField },
    data: () => ({
        questionValue: null
    }),
    props: {
        id: {
            type: String
        },
        value: {
            type: [String, Number],
            'default': null
        },
        label: {
            type: String,
            'default': ''
        },
        incorrect: {
            type: Boolean,
            'default': false
        },
        readonly: {
            type: Boolean,
            'default': true
        },
        required: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        colorClass () {
            return this.incorrect ? 'red--text font-weight-medium' : 'primary--text';
        }
    },
    created () {
        this.questionValue = +this.value;
    }
};
</script>
